<template>
  <div class="user-detail">
    <!-- top content -->
    <div class="top">
      <c-title icon="contacts">
        Dados do usuário
      </c-title>
      <!--       <c-button
        size="lg"
        class="edit"
        icon-size="lg"
        icon="pencil"
        @click="edit"
      >
        {{ isDesktop ? 'Editar' : '' }}
      </c-button> -->
    </div>

    <!-- detail content -->
    <div class="detail-content">
      <!-- profile -->
      <div :class="['profile', { '-inactive': !status }]">
        <div class="avatar">
          <c-image
            class="image-profile"
            :src="user.avatar || avatarDefault"
          />
          <c-icon
            :class="classes"
            size="40"
            icon="medal-empty"
            @click.native="$emit('confirmation', { id: user.id, status: !__isManager, manager: 'manager' , action: 'manager'})"
          />
        </div>

        <div class="info">
          <p class="name">
            {{ user.name }}
          </p>
          <div
            class="status"
            :style="'border: 1px solid ' + formatUserStatus(user.status).color"
          >
            <div
              class="dot"
              :style="'background-color: ' + formatUserStatus(user.status).color"
            />{{ formatUserStatus(user.status).label }}
          </div>
          <p class="cpf">
            {{ user.document }}
          </p>
        </div>

        <div class="actions">
          <c-button
            class="action"
            use-k-icon
            :icon="setActionButtonIcon(user.status)"
            size="md"
            :popover-label="user.status === 2 ? 'Convidar' : 'Suspender'"
            @click.native="$emit('confirmation', { id: user.id, status: user.status === 2 ? 0 : 2, action: 'status' })"
          />
          <c-button
            class="delete"
            icon="trash"
            size="md"
            popover-label="Excluir"
            @click.native="$emit('confirmation', { id: user.id, roles: user.roles, isDelete: true, action: 'delete' })"
          />
        </div>
      </div>

      <!-- user information  -->
      <c-card class="details">
        <div class="content">
          <div
            v-for="({ index, name, value }) in __user"
            :key="index"
            class="info-section"
          >
            <div class="section-title">
              <p class="title">
                {{ name }}
              </p>
            </div>
            <div class="section-content">
              <p
                class="content"
              >
                {{ value }}
              </p>
            </div>
          </div>
        </div>
      </c-card>
    </div>
    <div class="footer-buttons">
      <c-button
        v-if="isDesktop"
        class="buttons"
        type="button"
        size="lg"
        @click="$router.push({ name: 'usuarios' })"
      >
        Cancelar
      </c-button>

      <c-button
        class="buttons"
        primary
        icon="chevron-right"
        icon-size="sm"
        is-bold
        type="button"
        size="lg"
      >
        Salvar
      </c-button>
    </div>
  </div>
</template>

<script>
import CImage from '@/components/CComponents/CImage'
import CCard from '@/components/CComponents/CCard'

import translateEntity from '@/modules/translateEntity'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: { CImage, CCard },

  props: {
    user: {
      type: Object,
      required: true
    },

    status: Boolean
  },

  computed: {
    ...mapGetters({ company: types.COMPANY }),
    classes () {
      const classes = [
        'medal-lg', {
          '-manager': this.__isManager
        }]
      return classes
    },
    __user () {
      const labels = [
        ['name', 'NOME'],
        ['last_name', 'SOBRENOME'],
        ['email', 'EMAIL'],
        ['phone', 'TELEFONE'],
        ['birthday', 'Data de nascimento'],
        ['gender', 'GENERO'],
        ['city', 'LOCAL']
      ]

      return translateEntity(labels, this.user)
    },

    __isManager () {
      return this.user.roles && this.user.roles.includes('panel_manager')
    },

    avatarDefault () {
      return require('@/assets/img/userPlaceholder.svg')
    }
  },

  methods: {
    formatUserStatus (status) {
      const statusObj = {
        0: { label: 'convidado', color: '#F5A623' },
        1: { label: 'ativo', color: '#4CAD50' },
        2: { label: 'suspenso', color: '#D8D8D8' }
      }
      return statusObj[status]
    },
    setActionButtonIcon (status) {
      return status === 2 ? 'plus-add3' : 'block-delete-stop'
    },

    edit () {
      this.$router.push({
        name: 'usuarios-userId-editar',
        params: { userId: this.user.id }
      })
      this.handleAnalytics('botao-editar-usuario', { company_name: this.company.name })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-detail {
  padding: 0 10px;

  @include container;

  & > .top {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

    & > .edit {
      @include mobile { padding: 0 0 0 5px; }
    }
  }

  & > .detail-content {
  display: grid;
    margin-top: 20px;
    grid-template-areas:
        "userProfile userDetails"
        "userProfile userProducts";
   grid-template-columns: min-content auto;
   @include mobile { flex-direction: column; }

    & > .profile {
      display: flex;
      grid-area: userProfile;
      align-items: center;
      flex-direction: column;

      position: relative;

      width: 238px;
      max-height: 250px;

      border-radius: 3px;
      padding: 10px;

      background-color: #fff;
      box-shadow: 1px 1px 4px 0 $shadow-color;

      &.-inactive { background-color: #FBFCFE; }

      @include mobile { width: 100%; }

      &::before {
        content: "";
        display: block;

        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 75px;

        background-image: url('~/assets/img/pattern-oferta.svg');
        background-repeat: repeat-x;
      }

      & > .avatar {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 15px;

        & > .image-profile {
          border-radius: 50%;
          width: 85px;
          height: 85px;
        }

        & > .medal {
          position: absolute;

          padding: 3px;
          border: 2px solid #fff;
          border-radius: 15px;

          background-color: #414B6D;
          fill: $secondary-color-placeholder !important;
          fill: var(--color-secondary) !important;

          transform: translate(100%, 200%);
          cursor: pointer;
        }

        & > .medal-lg {
          position: absolute;
          left: 0;
          top: 0;

          border: solid 1px $another-color;
          border-radius: 50%;
          padding: 5px;

          background-color: white;
          fill: $secondary-color-placeholder !important;
          fill: var(--color-secondary) !important;
          cursor: pointer;
        }

        & > .-manager {
          background-color: $primary-color-placeholder;
          background-color: var(--color-primary);
        }
      }

      & > .info {
        margin-top: 15px;

        & > .name {
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: $base-color;
        }
        & > .status {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 8px;
          gap: 4px;
          min-width: 62px;
          height: 25px;
          border-radius: 2px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #5E6684;
          text-transform: capitalize;
          margin: 10px 0px;
          & > .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: inline-block;
          }
        }
        & > .cpf {
          font-size: 12px;
          text-align: center;
        }
      }

      & > .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 5px;
        & > .delete:not(:first-child) { margin-left: 10px; }

        & > .enable-user {
          font-size: 11px;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;

          height: 25px;
          border-radius: 3px;
          padding: 5px 10px;

          color: $primary-color-placeholder;
          color: var(--color-primary);
          background-color: rgba(18,30,72,0.1);
        }
      }
    }
    & > .details {
      grid-area: userDetails;
      width: 100%;
      margin-top: 10px;

      @include desktop {
        margin-left: 10px;
        margin-top: 0;
      }
      & > .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width:  100%;

        & > .info-section {
          display: flex;
          flex-grow: 1;

          margin: 0 10px;
          min-height: 60px;

          &:not(:last-child) { border-bottom: 1px solid $border-color; }

          & > .section-title {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            flex-grow: 1;
            flex-shrink: 0;

            margin-right: 20px;
            max-width: 145px;
            width: 100%;

            & > .title {
              font-size: 12px;
              font-weight: 400;
              color: $title-color;
              text-transform: uppercase;
            }
          }

          & > .section-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            flex-grow: 1;

            & > .content {
              font-size: 14px;
              font-weight: 400;
              word-break: break-all;
              color: $text-color;
            }
          }
        }
      }
    }
  }
}
.footer-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 250px;
    margin-top: 2.3rem;
    padding-top: 2.5rem;
    width: 47.5rem;
    border-top: 1px solid rgba(183, 187, 200, 0.9);

    > .buttons {
      height: 40px;
      width: 192px;

      @include mobile {
        width: 100%;
        height: 55px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }
</style>
